import React from 'react';
import styled from 'styled-components';
import { RiInformationLine } from 'react-icons/ri';
import { useInView } from 'react-intersection-observer';

const StyledInfoBox = styled.div`
  display: flex;
  background: var(--color-primary);
  color: #fff;
  padding: 20px 20px 20px 10px;
  border-radius: 6px;
  margin-bottom: 20px;
  b {
    display: inline;
  }
  p {
    margin: 0;
  }
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.min.s + 'px'}) {
    padding: 10px 20px 20px 20px;
    flex-direction: column;
    align-items: center;
  }
`;

const InfoBoxIcon = styled.div`
  font-size: 20px;
  margin-right: 10px;
  margin-top: 3px;
`;

function InfoBox({ children }) {
  return (
    <StyledInfoBox>
      <InfoBoxIcon>
        <RiInformationLine />
      </InfoBoxIcon>
      <p>{children}</p>
    </StyledInfoBox>
  );
}

const KbMainContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const KbContainer = styled.div`
  font-size: 20px;
  margin: 10px 0 20px 0;
`;

const KbPlus = styled.span`
  margin: 0 10px;
`;

const KbOr = styled.div`
  font-size: 12px;
  font-weight: bold;
  margin: 0 20px;
`;

const wideKeys = ['ctrl', 'alt', 'shift', 'tab', 'backspace', 'delete'];

function Kb({ keys, fontSize, join = 'or' }) {
  return (
    <>
      {Array.isArray(keys[0]) ? (
        <KbMainContainer>
          {keys.map((key, mainIndex) => (
            <>
              <KbContainer>
                {key.map((item, index) => (
                  <>
                    {wideKeys.includes(item.toLowerCase()) ? (
                      <kbd className="kbd-wide">{item}</kbd>
                    ) : (
                      <kbd>{item}</kbd>
                    )}
                    {index < key.length - 1 && <KbPlus>+</KbPlus>}
                  </>
                ))}
              </KbContainer>
              {mainIndex < keys.length - 1 && <KbOr>{join.toUpperCase()}</KbOr>}
            </>
          ))}
        </KbMainContainer>
      ) : (
        <KbContainer>
          {keys.map((key, index) => (
            <>
              {wideKeys.includes(key.toLowerCase()) ? (
                <kbd className="kbd-wide">{key}</kbd>
              ) : (
                <kbd>{key}</kbd>
              )}
              {index < keys.length - 1 && <KbPlus>+</KbPlus>}
            </>
          ))}
        </KbContainer>
      )}
    </>
  );
}

const VideoContainer = styled.div`
  margin-bottom: 20px;
`;

const StyledVideo = styled.video`
  width: 100%;
`;

function Video({ src, ...props }) {
  const [ref, inView] = useInView({ threshold: 0.1, triggerOnce: true });

  return (
    <VideoContainer ref={ref}>
      {inView && (
        <StyledVideo {...props}>
          <source src={src} type="video/mp4" />
        </StyledVideo>
      )}
    </VideoContainer>
  );
}
export { InfoBox, Video, Kb };
