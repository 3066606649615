import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  *, *:before, *:after {
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
  }

  body {
    background: var(--color-background);
    color: var(--color-text);
  }

  a {
    color: var(--color-link);
    text-decoration: none;
    transition: color 200ms ease-in-out;
    :hover {
      color: var(--color-link-hover);
    }
  }
`;

export default GlobalStyles;
