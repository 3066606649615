import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '../ThemeContext';
import GlobalStyles from '../GlobalStyles';

// Using a technique to display a container the full height of the
//    on mobile devices, which have issues doing so due to the extra
//    bars added to the screen, such as the Back and Forward buttons
//    on a Samsung
// https://chanind.github.io/javascript/2019/09/28/avoid-100vh-on-mobile-web.html

function App({ children }) {
  useEffect(() => {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    function setDocHeight() {
      document.documentElement.style.setProperty(
        '--vh',
        `${window.innerHeight / 100}px`
      );
    }
    window.addEventListener('resize', setDocHeight);
    window.addEventListener('orientationchange', setDocHeight);
    setDocHeight();
    return () => {
      window.addEventListener('resize', setDocHeight);
      window.addEventListener('orientationchange', setDocHeight);
    };
  }, []);
  return (
    <ThemeProvider>
      <GlobalStyles />
      {children}
    </ThemeProvider>
  );
}

export default App;
