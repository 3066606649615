export const THEME = {
  padding: {
    sides: 20,
  },
  animations: {
    xs: '150ms',
    s: '300ms',
    color: '150ms',
  },
  overlayBorder: {
    desktopSize: 18,
    mobileSize: 9,
  },
  breakpoints: {
    min: {
      xs: 324,
      s: 768,
      m: 959,
      l: 1024,
      xl: 1250,
      xxl: 1500,
    },
    max: {
      xs: 0,
      s: 0,
      m: 0,
      l: 0,
      xl: 1249,
      xxl: 0,
    },
  },
};

export const VARIANTS_TYPE_2_CONTAINER = {
  hidden: {},
  visible: { transition: { staggerChildren: 0.1, delayChildren: 0.1 } },
};

export const VARIANTS_TYPE_2_CHILD = {
  hidden: {
    y: 50,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: { ease: 'easeOut', duration: 1 },
  },
};

export const VARIANTS_CONTAINER_SLIDE = {
  before: {},
  after: { transition: { staggerChildren: 0.1, delayChildren: 1 } },
};
export const VARIANTS_INDIVIDUAL_SLIDE = {
  before: {
    y: 50,
    opacity: 0,
  },
  after: {
    y: 0,
    opacity: 1,
    transition: { ease: 'easeOut', duration: 1 },
  },
};

export const VARIANTS_CONTAINER_SLIDE_DOWN = {
  before: {},
  after: { transition: { staggerChildren: 0.1, delayChildren: 0.1 } },
};

export const VARIANTS_INDIVIDUAL_SLIDE_DOWN = {
  before: {
    y: -50,
    opacity: 0,
  },
  after: {
    y: 0,
    opacity: 1,
    transition: { ease: 'easeOut', duration: 1 },
  },
};

export const COLORS = {
  text: {
    light: 'hsl(0deg, 0%, 10%)',
    dark: 'hsl(0deg, 0%, 100%)',
  },
  background: {
    light: 'hsl(0deg, 0%, 100%)',
    dark: '#111517',
  },
  background2: {
    light: '#f5f5f5',
    dark: '#111517',
  },
  background3: {
    light: '#111517',
    dark: '#111517',
  },
  primary: {
    light: '#702d9e',
    dark: '#702d9e',
  },
  'primary-accent': {
    light: '#a736f3',
    dark: '#a736f3',
  },
  secondary: {
    light: 'hsl(250deg, 100%, 50%)',
    dark: 'hsl(190deg, 100%, 40%)',
  },
  link: {
    light: '#7700c8',
    dark: '#b446ff',
  },
  'link-hover': {
    light: '#9342cb',
    dark: '#c878ff',
  },
  gray300: {
    light: 'hsl(0deg, 0%, 70%)',
    dark: 'hsl(0deg, 0%, 30%)',
  },
  gray500: {
    light: 'hsl(0deg, 0%, 50%)',
    dark: 'hsl(0deg, 0%, 50%)',
  },
  gray700: {
    light: 'hsl(0deg, 0%, 30%)',
    dark: 'hsl(0deg, 0%, 70%)',
  },
  overlayBorder: {
    light: '#fff',
    dark: '#000',
  },
};

export const COLOR_MODE_KEY = 'color-mode';
export const INITIAL_COLOR_MODE_CSS_PROP = '--initial-color-mode';
