import { Video, Kb, InfoBox } from "../../../../src/components/MDX";
import Video1 from "../../../../src/content/blog-posts/General/vs-code-tips-and-tricks-1/1-expand-line-selection-ctrl-l.mp4";
import Video2 from "../../../../src/content/blog-posts/General/vs-code-tips-and-tricks-1/2-duplicate-whole-line-shift-alt-up-down.mp4";
import Video3 from "../../../../src/content/blog-posts/General/vs-code-tips-and-tricks-1/3-delete-whole-line-ctrl-shift-k.mp4";
import Video4 from "../../../../src/content/blog-posts/General/vs-code-tips-and-tricks-1/4-comment-single-line-ctrl-fwd-slash.mp4";
import Video5 from "../../../../src/content/blog-posts/General/vs-code-tips-and-tricks-1/5-toggle-block-coment-ctrl-shift-a.mp4";
import Video6 from "../../../../src/content/blog-posts/General/vs-code-tips-and-tricks-1/6-move-code-up-down-alt-up-down.mp4";
import Video7 from "../../../../src/content/blog-posts/General/vs-code-tips-and-tricks-1/7-html-emmet.mp4";
import Video8 from "../../../../src/content/blog-posts/General/vs-code-tips-and-tricks-1/8-wrap-with-abbreviation-ctrl-t.mp4";
import Video9 from "../../../../src/content/blog-posts/General/vs-code-tips-and-tricks-1/9-add-selection-next-find-match-ctrl-d.mp4";
import Video10 from "../../../../src/content/blog-posts/General/vs-code-tips-and-tricks-1/10-go-to-last-edit-location-ctrl-k-ctrl-q.mp4";
import Video11_1 from "../../../../src/content/blog-posts/General/vs-code-tips-and-tricks-1/11-1-rename-symbol-html-f2.mp4";
import Video11_2 from "../../../../src/content/blog-posts/General/vs-code-tips-and-tricks-1/11-2-rename-symbol-js-f2.mp4";
import Video12 from "../../../../src/content/blog-posts/General/vs-code-tips-and-tricks-1/12-search-through-files.mp4";
import Video13 from "../../../../src/content/blog-posts/General/vs-code-tips-and-tricks-1/13-delete-previous-word-ctrl-backspace.mp4";
import Video14_1 from "../../../../src/content/blog-posts/General/vs-code-tips-and-tricks-1/14-multiline-editing-1.mp4";
import Video14_2 from "../../../../src/content/blog-posts/General/vs-code-tips-and-tricks-1/14-multiline-editing-2.mp4";
import * as React from 'react';
export default {
  Video,
  Kb,
  InfoBox,
  Video1,
  Video2,
  Video3,
  Video4,
  Video5,
  Video6,
  Video7,
  Video8,
  Video9,
  Video10,
  Video11_1,
  Video11_2,
  Video12,
  Video13,
  Video14_1,
  Video14_2,
  React
};